/* You can add global styles to this file, and also import other style files */
body {
    margin : 0;
    font-size: 13px;
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    ;
    font-weight: 475;
    line-height: 20px;
}
button {
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
}
p {
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 475;
    font-size: 13px;
    line-height: 20px;
}

h1 {
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 38px;
}
h2 {
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}
h3 {
    font-family: 'Clan Pro',"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: none;

}


html, body { height: 100%; }

.material-icons {
    display: inline-flex;
    vertical-align: middle;
    font-family: 'Material Icons' !important;
}
.cursor-pointer{
    cursor: pointer;
}

.hover-background-grey:focus,.hover-background-grey:hover {
    background-color: #F1F1F1;
}
.toast-body{
    display: flex;
    width: 100%;
    justify-content: center;
}
.bg-success{
    width: 100%;
}
label{
    font-weight: bold
}

.px-104{
    padding-left: 104px;
    padding-right: 104px;
}
@media (max-width: 992px){
    .px-104{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
.p-dialog {
  padding:50px !important;

}
.password-no-border{
    border-right: 0;
}

.toast {
    width: 100%;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;
}
