@font-face {
    font-family: "ClanPro";
     src: url('../assets/fonts/clanpro/ClanWebPro-News.woff') format('woff');
    font-weight: normal;
    font-style: normal;
 }
  
 @font-face {
    font-family: "ClanPro";
     src: url('../assets/fonts/clanpro/ClanWebPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
 }
  
 @font-face {
    font-family: "ClanPro";
     src: url('../assets/fonts/clanpro/ClanWebPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
 }
  
 @font-face {
    font-family: "ClanPro";
    src: url('../assets/fonts/clanpro/ClanWebPro-NarrBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
 }

 #{lang()} {
     font-family: 'Arial';
 }
 
 h3#{lang()}, button#{lang()} {
     font-weight: 700;
 }