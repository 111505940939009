//
// Typography
//

h1, h2, h3, h5 {
  text-transform: uppercase;
}

//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  font-weight: $font-weight-bold;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    color: $gray-600;
  }
}

// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
  font-weight: $font-weight-bold;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    color: $gray-600;
    outline: 0;
  }
}