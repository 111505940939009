.carousel-control-next{
  background-image:none !important;
  filter:none !important;
}

.carousel-control-prev {
  background-image:none !important;
  filter:none !important;
}
.carousel-control-next-icon{
  background-image: url("../../assets/icons/arrow-point-to-right.png") !important;
}
.carousel-control-prev-icon{
  background-image: url("../../assets/icons/arrow-point-to-left.png") !important;
}
.carousel-indicators {
  display: none;
}
.carousel-control-prev, .carousel-control-next {
  opacity: 1;
  bottom: 200px;
}
