.btn,
a {
  &:focus,
  :active:focus,
  .active:focus {
    outline: unset;
    color: unset;
  }

  &:focus-visible {
    outline: 2px solid $petrol;
    outline-offset: unset;
  }

  &.btn-danger {
    color: var(--bs-btn-hover-color);
  }
}
