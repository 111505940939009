.form-control {
  padding: $input-padding-y $input-padding-x;
  border: 1px solid $border-color;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.075);
  font-weight: inherit;
  color: $gray-800;

  &:focus,
  &.ng-invalid:focus {
    border-color: $border-color;
    outline: 2px $petrol solid;
    box-shadow: none;
  }

  &+label {
    font-weight: 500;
    color: $gray-800;
  }

  &+label#{lang()} {
    font-weight: bold;
  } 
}

.form-floating {

  &>.form-control:focus,
  &>.form-control:not(:placeholder-shown),
  &>.form-select {
    &~label {
      opacity: 1;
      color: rgba(var(--bs-gray-dark), 1);
    }
  }
}

.form-select {
  padding: $input-padding-y $input-padding-x;
  font-weight: inherit;
  background-size: 36px 12px;

  &:focus {
    border-color: $border-color;
    outline: 2px #238c96 solid;
    box-shadow: none;
  }

  &+label {
    font-weight: 500;
  }

  &+label#{lang()} {
    font-weight: bold;
  } 
}

.form-check-input {

  &:focus-visible {
    border-color: $border-color;
    outline: 2px $petrol solid;
    box-shadow: none;
  }

  &[type=checkbox]:focus {
    border-color: $border-color;
    box-shadow: none;
  }

  &+label {
    font-weight: inherit;
    color: #666;
  }

  &:checked[type=checkbox] {
    border: none;
    outline: none;
  }
}

.custom-control-input {
  &.ng-valid {

    &.ng-touched:not([ng-reflect-model=""])~.custom-control-label,
    &.ng-untouched:not([ng-reflect-model=""])~.custom-control-label {
      color: black;
    }

    &.ng-touched:checked:not([ng-reflect-model=""])~.custom-control-label::before,
    &.ng-untouched:checked:not([ng-reflect-model=""])~.custom-control-label::before {
      border-color: grey;
      background: black;
    }

    &.ng-touched {

      &:not([ng-reflect-model=""])~.custom-control-label::before,
      &:focus:not(:checked):not([ng-reflect-model=""])~.custom-control-label::before {
        border-color: grey;
      }
    }
  }

  &:not(:disabled):active~.custom-control-label::before {
    background-color: transparent;
  }
}

input[type=date]::-webkit-calendar-picker-indicator {
  padding: $form-floating-input-padding-b 0 $form-floating-input-padding-t 0;
}
