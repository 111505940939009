
@use '@angular/material' as mat;
@include mat.core();

$brand: (
  50 : #fffde0,
  100 : #fffab3,
  200 : #fff680,
  300 : #fff24d,
  400 : #fff026,
  500 : #ffed00,
  600 : #ffeb00,
  700 : #ffe800,
  800 : #ffe500,
  900 : #ffe000,
  A100 : #ffffff,
  A200 : #fffdf2,
  A400 : #fff6bf,
  A700 : #fff3a6,
    contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$grey: (
  50 : #eeeeee,
  100 : #d6d6d6,
  200 : #bababa,
  300 : #9e9e9e,
  400 : #8a8a8a,
  500 : #757575,
  600 : #6d6d6d,
  700 : #626262,
  800 : #585858,
  900 : #454545,
  A100 : #f6afaf,
  A200 : #f18282,
  A400 : #ff4141,
  A700 : #ff2727,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);



$ak-my-kaercher-frontend-primary: mat.define-palette($grey);
$ak-my-kaercher-frontend-accent: mat.define-palette($brand);

$ak-my-kaercher-frontend-theme: mat.define-light-theme((
  color: (
    primary: $ak-my-kaercher-frontend-primary,
    accent: $ak-my-kaercher-frontend-accent,
  )
));

@include mat.all-component-themes($ak-my-kaercher-frontend-theme);


//@import '../node_modules/@kaercher/bootstrap/scss/styles';
// variables for library

@import'./scss/custom/styles';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import './scss/custom/accessibility/button';
