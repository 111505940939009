
$grid-columns: 120;

$grid-breakpoints: (
  md: 0,
  lg: 992px,
  xl: 1280px,
);
$grid-gutter-width: 0px;

$container-max-widths: (
  md: 992px,
  lg: 1230px,
);
